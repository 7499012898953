.footer {
    width: 100%;
    height: 10px;
}
.footer-home {
    background-color: #0ff;
}

.footer-works {
    background-color: #90ff00;
}

.footer-contact {
    background-color: #f000ff;
}

.footer-resume {
    background-color: #fff000;
}