.content {
    margin-top: 68px;
}

hr {
    background-color: #fb0;
}

h1 {
    color: #fb0;
}

.content-home {
    color: #0ff;
    left: 0;
    right: 0;
    /*padding: 10px;*/
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
}

.content-works {
    color: #90ff00;
}

.content-contact {
    color: #f000ff;
}

.content-resume {
    color: #fff000;
}

.homeButtons {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-around;
    /*justify-content: center;*/
}


.ba {
    border-style: solid;
    border-width: 5px;
}

.b--black-10 {
    border-color: #fb0;
}

.br-100 {
    border-radius: 100%;
}

.h3 {
    height: 10rem;
}

.w3 {
    width: 10rem;
}

.pa1 {
    padding: .25rem;
}

.pa4 {
    padding: 2rem;
}

.tc {
    text-align: center;
}

.button-large {
    background-color: #fb0;
    border: 2px solid #fb0;
    outline: none;
    width: 200px;
    /*font-weight: bold;*/
    color: black;
    padding: 16px 32px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin: 10px 10px;
    transition-duration: 0.4s;
    cursor: pointer;
    border-radius: 30px;
}
.button-large-works:hover {
    border: 2px solid #90ff00;
    color: #90ff00;
    outline: none;
    background-color: black;
}

.button-large-contact:hover {
    border: 2px solid #f000ff;
    color: #f000ff;
    outline: none;
    background-color: black;
}
