.aButton {
    margin: 0;
    padding: 17px 17px 27px 17px;
    text-decoration: none;
    color: white;
}
.aButton:hover {
    text-decoration: none;
}

.navbar{
    padding: 0;
    display: flex;
    justify-content: center;
    background-color: black;
    color: white;
}

.navbar-home {
    box-shadow: inset 0 -10px 0px #0ff;
}

.navbar-works {
    box-shadow: inset 0 -10px 0px #90ff00;
}

.navbar-contact {
    box-shadow: inset 0 -10px 0px #f000ff;
}

.navbar-resume {
    box-shadow: inset 0 -10px 0px #fff000;
}


.button-home:hover {
    box-shadow: inset 0 -10px 0px #0ff;
    color: #0ff;
}

.button-works:hover {
    box-shadow: inset 0 -10px 0px #90ff00;
    color: #90ff00;
}

.button-contact:hover {
    box-shadow: inset 0 -10px 0px #f000ff;
    color: #f000ff;
}

.button-resume:hover {
    box-shadow: inset 0 -10px 0px #fff000;
    color: #fff000;
}